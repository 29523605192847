<template>
    <div>
        <section id="page-title" class="page-title-parallax page-title-dark" style="background-image: url('/assets/images/about/parallax.jpg'); padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

			<div class="container clearfix">
				<h1>Job Openings</h1>
				<span>Join our Fabulous Team of Intelligent Individuals</span>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">Jobs</li>
				</ol>
			</div>

		</section><!-- #page-title end -->
        <!-- Content
		============================================= -->
		<section id="content">
			<div class="content-wrap">
				<div class="container clearfix">
                    
					<div class="row col-mb-50">
                        <!-- sidebar -->
                        <div class="col-md-4">
                            <Sidebar/>
                        </div>
                        <!-- JD  -->
						<div class="col-md-8">
							<div>
                                <h3 style="width: 100%;padding: 0 0 0.75rem;border-bottom: 2px solid #1ABC9C;">Lead Generation Specialist
                                <router-link to="/careers/lead-generation-specialist/form" class="button button-3d m-0 button-primary" style="float:right">Apply Now</router-link></h3>
                                <h4>The Role</h4>
                                <p>We are looking for an awesome Lead Generation Specialist who will join our growing company. An ideal candidate should be able to prospect, filter viable leads and acquire potential partners.</p>
                                <p>We know it can be tricky to apply for roles, wondering if the position is right for you and if you and your experience are suitable for the role. Many people won't apply for roles unless they feel that they tick every single box. At eFlex, we look for many different skills and abilities, and we're always looking for how new team members can add to eFlex and our culture. So if you don't think you quite meet all of the skills listed, we'd still love to hear from you!</p>

                                <div class="accordion accordion-bg">

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll be doing
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Evaluate and filter leads</li>
                                            <li><i class="icon-ok"></i>Prospect for leads and possible partners</li>
                                            <li><i class="icon-ok"></i>Contact and set up meetings with business customers and establish a relationship</li>
                                            <li><i class="icon-ok"></i>Gather correct customer information during a conversation to make an appointment</li>
                                            <li><i class="icon-ok"></i>Actively source new business opportunities</li>
                                            <li><i class="icon-ok"></i>Submit reports on sales lead journey</li>
                                            <li><i class="icon-ok"></i>Investigate and find contact details for targeted key areas</li>
                                            <li><i class="icon-ok"></i>Assist the sales team in managing sales and marketing campaigns including follow up calls</li>
                                            <li><i class="icon-ok"></i>Develop and maintain sales prospecting database for the company ensuring that all new entries in the database are of an acceptable standard in terms of accuracy</li>
                                            <li><i class="icon-ok"></i>Source new records for the database as required</li>
                                            <li><i class="icon-ok"></i>Maintain confidentiality</li>
                                            <li><i class="icon-ok"></i>Generate target outputs for appointments with decision makers.</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            Minimum Qaulifications
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>At least 1-2 years experience with the same role</li>
                                            <li><i class="icon-ok"></i>Knowledge in certain sales processes</li>
                                            <li><i class="icon-ok"></i>Problem-solver and go getter attitude</li>
                                            <li><i class="icon-ok"></i>Researching and data mining skills</li>
                                            <li><i class="icon-ok"></i>Previous background in cold calling, lead generation, telemarketing or telesales is a plus but not necessary</li>
                                            <li><i class="icon-ok"></i>Proficient in MS Office and Google apps</li>
                                            <li><i class="icon-ok"></i>Experience in using any CRM</li>
                                            <li><i class="icon-ok"></i>Ability to adapt to new tools and software</li>
                                            <li><i class="icon-ok"></i>High level of accuracy and attention to details</li>
                                            <li><i class="icon-ok"></i>Ability to think on your feet</li>
                                            <li><i class="icon-ok"></i>Ability to articulate a value proposition and get to the decision maker</li>
                                            <li><i class="icon-ok"></i>Excellent communication skills both verbal and written.</li>
                                        </ul>
                                    </div>
                                    
                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll enjoy at eFlex
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-plus-sign"></i>We live our values as playmakers, obsessed with learning, care personally about our colleagues and partners, are radically open-minded, and take pride in everything we do.</li>
                                            <li><i class="icon-plus-sign"></i>We offer a Competitive Salary.</li>
                                            <li><i class="icon-plus-sign"></i>Work-from-home setup as we prepare to provide a safe environment for our employees. Our Future of Work plan is to move towards a hybrid work model (in-office & from home).</li>
                                            <li><i class="icon-plus-sign"></i>We offer comprehensive HMO and optical reimbursements.</li>
                                            <li><i class="icon-plus-sign"></i>Recharge and go on your next getaway or simply take time off for yourself through our flexible personal & sick days. We want our team to be happy and healthy :)</li>
                                            <li><i class="icon-plus-sign"></i>We support our employee's career growth and development by offering opportunities for promotion.</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-title">
                                            About eFlexervices
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <p>eFlexervices is a 23-year-old BPO company providing exceptional quality and unwavering trust. Our partnership approach to the business allows us to match the right talent to every organization we support. We invest in people to optimize performance and maximize efficiency. We work hard to produce the highest possible results for our partners.</p>
                                        <p>Performance is the underlying foundation that drives eFlexervices. We deliver the metrics our partners expect through proper recruitment and heavy investment in the right people.</p>
                                        <p>Deep integration within our partners' organizations drives our team to take ownership of their work. This stakeholder mindset pushes higher performance, better quality, and longer retention.</p>
                                    </div>
                                    <router-link to="/careers/lead-generation-specialist/form" class="button button-3d m-0 button-primary" style="float:left">Apply Now</router-link>
                                </div>
                                <div class="divider divider-sm"></div>
                            </div>
						</div>
                        
                    </div>

				</div>
			</div>
		</section><!-- #content end -->

    </div>
</template>

<script>
import Sidebar from '@/components/CareerNav.vue'
export default {
    data() {
        return {
            
        }
    },
    components: {
        Sidebar
    },
    mounted() {
		window.scrollTo(0, 0)
	}
}
</script>